import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light: {
                primary: "#5bc0de",
                secondary: "#FFFFFF",

                heading: "#000000",
                grey: "#EEEEEE",
                "dark-grey": "#DDDDDD",
                dark: "#000000",
                background: "#F9F9F9",
                secondaryBackground: "FFFFFF",
                light: "#FFFFFF",

                success: "#46c93a",
                warning: "#ffba00",
                error: "#ff4757",
            },
            dark: {
                primary: "#5bc0de",
                secondary: "#FFFFFF",

                heading: "#FFFFFF",
                grey: "#444444",
                "dark-grey": "#DDDDDD",
                dark: "#FFFFFF",
                background: "#121212",
                secondaryBackground: "#1f1f1e",
                light: "#000000",

                success: "#46c93a",
                warning: "#ffba00",
                error: "#ff4757",
            },
        },
    },
});

//DEFAUT

// themes: {
//     light: {
//         primary: "#5bc0de",
//         secondary: "#FFFFFF",

//         heading: "#000000",
//         grey: "#EEEEEE",
//         "dark-grey": "#DDDDDD",
//         dark: "#000000",
//         background: "#F9F9F9",
//         secondaryBackground: "FFFFFF",
//         light: "#FFFFFF",

//         success: "#46c93a",
//         warning: "#ffba00",
//         error: "#ff4757",
//     },
//     dark: {
//         primary: "#5bc0de",
//         secondary: "#FFFFFF",

//         heading: "#FFFFFF",
//         grey: "#444444",
//         "dark-grey": "#DDDDDD",
//         dark: "#FFFFFF",
//         background: "#121212",
//         secondaryBackground: "#1f1f1e",
//         light: "#000000",

//         success: "#46c93a",
//         warning: "#ffba00",
//         error: "#ff4757",
//     },
// },
