// Import the `getField` getter and the `updateField`
// mutation function from the `vuex-map-fields` module.
import { getField, updateField } from "vuex-map-fields";

///////////////////////////////////

const state = {
    org: "",
    orgData: {
        data: {
            realmGroup: {
                realmLive: { _id: null },
                realmPublic: { _id: null },
                realmSandbox: { _id: null },
            },
        },
    },
};

///////////////////////////////////

const mutations = {
    updateField,
    setOrg(state, org) {
        state.org = org;
    },
    setOrgData(state, orgData) {
        state.orgData = orgData;
    },
};

///////////////////////////////////

const getters = {
    getField,
    getOrg: (state) => state.org,
    getOrgData: (state) => state.orgData,
};

///////////////////////////////////

const actions = {};

///////////////////////////////////

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
