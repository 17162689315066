<template>
    <v-app id="app" :class="[$vuetify.theme.dark ? 'dark' : 'light']">
        <v-card
            color="secondaryBackground"
            tile
            flat
            width="100%"
            height="80"
            class="d-flex align-center px-3 flex-shrink-0"
            :class="{ 'px-6 justify-space-between': $vuetify.breakpoint.mdAndUp }"
            v-if="!hideNavigation"
        >
            <!--justify-space-between-->
            <div class="logo d-flex align-center mr-4 cursor-pointer" v-if="$vuetify.breakpoint.mdAndUp">
                <template v-if="$vuetify.theme.dark">
                    <img :src="getLogo(orgData.data.logoDark)" height="35" />
                </template>

                <template v-else>
                    <img :src="getLogo(orgData.data.logoLight)" height="35" />
                </template>

                <template v-if="$app.user">
                    <div class="ml-8 d-flex flex-column" v-if="$vuetify.breakpoint.mdAndUp">
                        <b>{{ $app.user.name }}</b>
                        <p class="muted ma-0 caption">{{ orgData.title }}</p>
                    </div>
                </template>
            </div>

            <v-btn icon class="mr-4" @click="drawer = !drawer" v-else>
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <div class="d-flex align-center" :class="{ 'justify-end full-width': $vuetify.breakpoint.mdAndDown }">
                <v-menu offset-y transition="slide-y-transition" content-class="zindex-11">
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                            class="ml-4"
                            :size="$vuetify.breakpoint.mdAndDown ? 36 : 40"
                            v-bind="attrs"
                            v-on="on"
                            :class="[$route.name == 'profile' ? 'active' : '']"
                        >
                            <template v-if="$app.user && $app.user.contacts && $app.user.contacts.length">
                                <v-img
                                    class="grey"
                                    :aspect-ratio="1"
                                    :src="$fluro.asset.avatarUrl($app.user.contacts[0], 'contact')"
                                ></v-img>
                            </template>

                            <template v-else>
                                <v-card
                                    class="elevation-0 grey d-flex align-center justify-center"
                                    :width="$vuetify.breakpoint.mdAndDown ? 36 : 40"
                                    :height="$vuetify.breakpoint.mdAndDown ? 36 : 40"
                                >
                                    <v-icon color="dark-grey">mdi-account-outline</v-icon>
                                </v-card>
                            </template>
                        </v-avatar>
                    </template>

                    <v-list class="list">
                        <v-list-item class="px-4">
                            <v-list-item-avatar v-if="$app.user && $app.user.contacts && $app.user.contacts.length">
                                <v-img
                                    class="grey"
                                    :aspect-ratio="1"
                                    :src="$fluro.asset.avatarUrl($app.user.contacts[0], 'contact')"
                                ></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <template v-if="$app.user">
                                    <b v-html="$app.user.name"></b>
                                    <v-list-item-subtitle v-html="orgData.title"></v-list-item-subtitle>
                                </template>
                            </v-list-item-content>
                        </v-list-item>

                        <div class="px-4">
                            <v-divider></v-divider>
                        </div>

                        <v-list-item class="px-4" link :to="{ name: 'profile' }">
                            <v-list-item-icon>
                                <v-icon color="dark">mdi-account-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>My profile</v-list-item-title>
                        </v-list-item>

                        <v-list-item class="px-4" link @click="switchTheme()">
                            <v-list-item-icon>
                                <v-icon
                                    color="dark"
                                    v-html="$vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night'"
                                ></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                                v-html="$vuetify.theme.dark ? 'Light mode' : 'Dark mode'"
                            ></v-list-item-title>
                        </v-list-item>

                        <div class="px-4">
                            <v-divider></v-divider>
                        </div>

                        <v-list-item class="px-4" link @click="$fluro.auth.logout()">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-card>

        <div class="d-flex full-width overflow-hidden flex-grow-1">
            <!-- <v-hover v-model="hovering"> -->
            <div class="p-relative flex-grow-1" @mouseenter="enter" @mousemove="move" @mouseleave="leave">
                <transition name="fade" mode="out-in" appear>
                    <v-btn
                        ref="hoverButton"
                        v-if="hovering && $vuetify.breakpoint.mdAndUp"
                        class="menu-expand"
                        fab
                        x-small
                        color="primary"
                        @click="drawer = !drawer"
                    >
                        <v-icon v-html="drawer ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                    </v-btn>
                </transition>

                <v-navigation-drawer
                    ref="navigation"
                    class="pa-0 zindex-11 padded-height"
                    v-if="!hideNavigation"
                    v-model="drawer"
                    :permanent="$vuetify.breakpoint.mdAndUp"
                    :app="$vuetify.breakpoint.mdAndDown"
                    :mini-variant="!drawer"
                >
                    <!-- expand-on-hover -->

                    <v-card
                        class="pa-4 d-flex flex-column justify-space-between full-height full-width"
                        color="secondaryBackground"
                        tile
                        flat
                    >
                        <v-list dense nav class="px-1 py-0">
                            <div class="my-4" v-if="$vuetify.breakpoint.mdAndDown">
                                <template v-if="$vuetify.theme.dark">
                                    <img :src="getLogo(orgData.data.logoDark)" height="35" />
                                </template>

                                <template v-else>
                                    <img :src="getLogo(orgData.data.logoLight)" height="35" />
                                </template>
                            </div>

                            <v-list-item
                                :disabled="item.disabled"
                                link
                                color="primary"
                                v-for="(item, index) in menu"
                                :key="index"
                                :to="{ name: item.name, path: item.path }"
                                class="navigation-inactive my-2"
                                active-class="navigation-active"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        ><b>{{ item.title }}</b></v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                link
                                color="primary"
                                class="navigation-inactive my-2"
                                active-class="navigation-active"
                            >
                                <v-list-item-content>
                                    <v-list-item-title> </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                link
                                color="primary"
                                class="navigation-inactive my-2"
                                active-class="navigation-active"
                            >
                                <v-list-item-content>
                                    <v-list-item-title> </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                link
                                color="primary"
                                class="navigation-inactive my-2"
                                active-class="navigation-active"
                            >
                                <v-list-item-content>
                                    <v-list-item-title> </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <!--Admin-->
                        <!-- <v-list v-show="$app.isAdmin" class="mt-auto" dense nav> -->
                        <v-list dense nav class="px-1 py-0">
                            <v-list-item
                                link
                                color="primary"
                                :href="`${orgData.data.links.homepage}`"
                                target="_blank"
                                class="navigation-inactive"
                                active-class="navigation-active"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-open-in-new</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><b>Back to website</b></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                                link
                                color="primary"
                                :to="{ name: 'print', path: '/print' }"
                                class="navigation-inactive"
                                active-class="navigation-active"
                                v-if="$app.isPrinter"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-printer</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><b>Print Report</b></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                                link
                                color="primary"
                                :to="{ name: 'admin', path: '/admin' }"
                                class="navigation-inactive"
                                active-class="navigation-active"
                                v-if="$app.isAdmin"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-account-cog-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title><b>Admin</b></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <!-- <v-list class="mt-auto" dense nav>
                        <v-list-item disabled link color="primary" :to="{name : 'help', path: '/help'}" class="navigation-inactive" active-class="navigation-active">
                            <v-list-item-icon>
                                <v-icon>mdi-help-circle-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title><b>Help &amp; Support</b></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list> -->
                    </v-card>
                </v-navigation-drawer>
            </div>
            <!-- </v-hover> -->

            <v-main>
                <router-view :key="$route.fullPath"></router-view>
            </v-main>
        </div>
    </v-app>
</template>

<script>
import AlertDialog from "@/components/dialogs/alert-dialog.vue";
import AppFooter from "@/components/layout/app-footer.vue";
// import AppNavigation from "@/components/layout/app-navigation.vue";

import UserMixin from "@/mixins/user-mixin";

import _ from "lodash";

export default {
    name: "app",

    components: {
        AlertDialog,
        AppFooter,
        // AppNavigation,
    },

    mixins: [UserMixin],

    meta: {
        titleTemplate: "%s - Prize Art Software ",
    },

    data() {
        return {
            scrolled: false,
            search: "",
            drawer: false,
            hovering: false,
            expanded: null,
            judge: false,
        };
    },

    computed: {
        orgData() {
            console.log("STORE", this.$store.getters["org/getOrgData"]);
            return this.$store.getters["org/getOrgData"];
        },
        hideNavigation() {
            return this.$route.meta.hideNavigation;
        },

        hideFooter() {
            return this.$route.meta.hideFooter;
        },

        menu() {
            let menu = [];

            if (this.$app.isArtist) {
                menu.push({
                    title: "My Submissions",
                    name: "my-submissions",
                    icon: "mdi-image-multiple-outline",
                    path: "/my-submissions",
                });
            }

            if ((this.$app.isJudge && !this.$app.isArtist) || this.$app.isJudge) {
                menu.push({
                    title: "Online Judging",
                    name: "online-judging",
                    icon: "mdi-account-box-multiple-outline",
                    path: "/online-judging",
                });

                menu.push({
                    title: "Live Competitions",
                    name: "live-competitions",
                    icon: "mdi-calendar-star",
                    path: "/live-competitions",
                });
            }

            menu.push({
                title: "FAQs",
                name: "faqs",
                icon: "mdi-help-circle-outline",
                path: "/faqs",
            });

            return menu;
        },
    },

    methods: {
        loadScript() {
            const script = document.createElement("script");
            const awards = this.orgData.data.links.awards;
            script.src = `https://${awards}/appdata.js`; // or your dynamic URL
            console.log("Changing Scridpddstt", script.src);
            script.async = true;
            document.body.appendChild(script);
        },
        getLogo(logo) {
            if (logo) {
                return this.$fluro.asset.imageUrl(logo._id, 400);
            }
        },
        handleScroll(e) {
            if (e.target.scrollTop >= 140) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
        switchTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            window.localStorage["dark"] = this.$vuetify.theme.dark;
        },
        switchType() {
            window.sessionStorage["judge"] = this.judge;

            if (this.judge) {
                this.$router.push({ name: "online-judging" });
            } else {
                this.$router.push({ name: "my-submissions" });
            }
        },
        enter(e) {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.hovering = true;
            }
        },
        move(e) {
            if (this.$vuetify.breakpoint.mdAndUp) {
                let button = this.$refs.hoverButton.$el;
                let navigation = this.$refs.navigation.$el;

                let navigationContainer = navigation.getBoundingClientRect();
                let buttonContainer = button.getBoundingClientRect();

                let navTop = navigationContainer.top;
                let cursor = e.y - navTop - buttonContainer.height / 2;

                let topPadding = 32;
                let bottomPadding = navigationContainer.bottom - navTop - (navTop - topPadding);

                if (cursor >= topPadding && cursor <= bottomPadding) {
                    // If cursor is somewhere in the middle
                    button.style.top = cursor + "px";
                } else {
                    if (cursor < topPadding) {
                        // Cursor is at the top
                        button.style.top = topPadding + "px";
                    } else {
                        // Cursor is at the bottom
                        button.style.top = bottomPadding + "px";
                    }
                }
            }
        },
        leave(e) {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.hovering = false;
            }
        },
        // goHome() {
        //     if (this.judge) {
        //         this.$router.push({ name: "online-judging" });
        //     } else {
        //         this.$router.push({ name: "my-submissions" });
        //     }
        // },
    },
    watch: {
        // $route: {
        //     immediate: true,
        //     handler(to, from) {
        //         document.title = to.meta.title || "Some Default Title";
        //     },
        // },
    },
    mounted() {
        setTimeout(() => {
            console.log("VUETIFY", this.$vuetify);
            this.$vuetify.theme.themes.dark = this.$app.darkTheme;
            this.$vuetify.theme.themes.light = this.$app.lightTheme;
            console.log(
                "default",
                localStorage.getItem("dark"),
                this.$store.getters["org/getOrgData"].data.themes.defaultDark
            );
        }, 2000);

        document.querySelector(".v-main__wrap").addEventListener("scroll", this.handleScroll);
    },

    destroyed() {
        document.querySelector(".v-main__wrap").removeEventListener("scroll", this.handleScroll);
    },

    created() {
        this.judge = eval(window.sessionStorage.getItem("judge") || false);

        setTimeout(() => (this.$vuetify.theme.dark = eval(window.localStorage.getItem("dark")) || false));
        console.log(
            "%cMade by IYP Software",
            "font-size:11px; font-weight:bold; color:#f6f6f6; background-color:#131414; padding:6px 7px;border-radius:3px;"
        );
        this.loadScript();
    },
};
</script>
<style lang="scss">
* {
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;

    // ::-webkit-scrollbar {
    //     display: none;
    // }
}

html,
body,
#app,
.v-application--wrap {
    min-height: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: column;
    overflow: hidden;
}

.v-main {
    width: 100% !important;
    overflow: hidden !important;
    flex-shrink: 1 !important;
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.page-header {
    transition: background-color 0.2s, padding-top 0.2s, padding-bottom 0.2s;

    h2 {
        transition: font-size 0.2s;
    }

    &.scrolled {
        background-color: var(--v-light-base);
        padding-top: 8px !important;
        padding-bottom: 8px !important;

        h2 {
            font-size: 18px !important;
        }
    }
}

.submission {
    .asset-outer {
        border-radius: 8px;
        background: var(--v-dark-grey-base);
        padding: 16px;
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
    }
}

.wrapper {
    padding: 8% 0;
}

.group-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    border: 1px solid var(--v-grey-base);
    background: var(--v-light-base);
}

.border-bottom {
    border-bottom: 1px solid var(--v-grey-base);
}

.transparent-field {
    .v-input__control {
        .v-input__slot {
            padding: 0 !important;
            background: transparent !important;
        }
    }

    &.large--text {
        .v-input__control {
            .v-input__slot {
                input {
                    font-size: 24px;
                    font-weight: 700;
                }
            }
        }
    }

    &.small--text {
        .v-input__control {
            .v-input__slot {
                input {
                    font-size: 14px;
                }
            }
        }
    }
}

.dialog-error {
    height: 0;
    overflow: hidden;
    transition: height 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.active {
        height: 60px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.full-height-table {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .v-data-table__wrapper {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        th {
            position: -webkit-sticky;
            /* for Safari */
            position: sticky;
            top: 0;
            background: #ffffff;
            z-index: 10;
            white-space: nowrap;

            span {
                white-space: nowrap;
                margin-right: 10px;
            }
        }

        tfoot {
            position: -webkit-sticky;
            /* for Safari */
            position: sticky;
            bottom: 0;
            background: #ffffff;
            z-index: 10;
        }
    }
}

.no-wrap {
    white-space: nowrap;
}

.light {
    background: var(--v-background-base) !important;

    .v-icon {
        // color: #222222;
    }
}

div {
    font-weight: 400 !important;
}

.initial--text {
    font-size: initial !important;
}

.xx-large--text {
    font-size: 50px !important;
}

.x-large--text {
    font-size: 36px !important;
}

th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    top: 0;
    background: var(--v-light-base);
    z-index: 10;
    white-space: nowrap;

    span {
        white-space: nowrap;
        // margin-right: 10px;
    }
}

.medium--text {
    font-size: 18px !important;
}

.small--text {
    font-size: 14px !important;
}

.x-small--text {
    font-size: 10px !important;
}

.xx-small--text {
    font-size: 6px !important;
}

.bold--text {
    font-weight: 600 !important;
}

.normal--text {
    font-weight: normal !important;
}

.line-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.divider {
    margin: 0;

    &.vertical {
        margin: 0 5px;
        border-right: 1px solid var(--v-dark-grey-base);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}

b {
    font-weight: 600 !important;
}

.list {
    div {
        font-size: 14px !important;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;

    li {
        position: relative;
        width: calc(100% - 20px);
        margin-left: auto;

        &:before {
            content: "";
            background: var(--v-primary-base);
            font-weight: bold;
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 5.5px;
            left: -20px;
            border-radius: 2px;
        }
    }
}

.styled-button {
    align-items: center;
    background: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
    border-radius: 3px;
    box-shadow: inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff, 0 1px 2px 1px rgba(30, 35, 90, 0.4);
    color: #969faf;
    display: flex;
    justify-content: center;
    // margin-right: 0.4em;
    padding: 0 4px 2px;
    height: 18px;
    position: relative;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(-225deg, #cccccc, #f1f1f1);
    }

    .v-icon {
        color: #969faf;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.v-expansion-panel {
    margin-bottom: 20px;
    border: 1px solid rgba(#cccccc, 0.3);
    border-radius: 4px;

    &:after {
        border: none !important;
    }

    &:before {
        box-shadow: none;
    }

    &:last-child {
        margin: 0;
    }

    .v-expansion-panel-header {
        padding: 15px;
        border-radius: 4px !important;

        &:before {
            background-color: var(--v-dark-lighten3);
        }
    }

    .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
            padding: 15px;
        }

        &:after {
            border: none !important;
        }
    }
}

.v-application {
    .content-select,
    .field {
        font-size: 14px !important;

        .v-input__control {
            // min-height: 36px !important;

            .v-input__slot {
                background: var(--v-grey-base);
                border: 1px solid rgba(#cccccc, 0.3);
                // min-height: 36px !important;

                .v-text-field__slot {
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }

                    input[type="time"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }

                    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }
                }
            }

            .v-select__slot {
                .v-select__selections {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow-x: scroll;

                    .v-chip {
                        flex-shrink: 0;
                        background: var(--v-primary-base);
                        color: #ffffff !important;
                    }
                }
            }
        }

        &.v-input--is-focused {
            .v-input__control {
                .v-input__slot {
                    border-color: var(--v-primary-base) !important;
                }
            }
        }

        // &.v-input--is-dirty {
        //     .v-input__control {
        //         .v-input__slot {
        //             border-color: var(--v-success-base)!important;
        //         }
        //     }
        // }

        &.error--text {
            .v-input__control {
                .v-input__slot {
                    border-color: var(--v-error-base) !important;
                }
            }
        }
    }

    .content-select:not(.v-textarea),
    .field:not(.v-textarea) {
        .v-input__control {
            .v-input__slot {
                height: 36px;
            }
        }
    }
}

.caption {
    font-family: "Poppins", sans-serif !important;
}

a {
    text-decoration: none;
}

.view-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    flex-shrink: 0;

    b {
        font-size: 14px;
    }
}

.view-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
    flex-shrink: 0;
    margin-top: auto;
}

.text-overflow {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 15px;

    b,
    p,
    span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.v-btn {
    font-weight: bold;
    text-transform: none;
    border-radius: 4px !important;
    overflow: hidden;
    font-weight: 600;

    &:not(.v-btn--icon, .v-btn--text, .theme--light, .theme--dark) {
        color: var(--v-light-base) !important;
    }

    &.v-btn--fab {
        &.v-size--small {
            width: 36px !important;
            height: 36px !important;
        }
    }

    .v-ripple__container {
        border-radius: 4px !important;

        .v-ripple__animation {
            border-radius: 4px !important;
        }
    }

    &:before {
        border-radius: 4px !important;
    }

    &.outline {
        border: 1px solid;

        &.dark-grey--outline {
            border-color: var(--v-dark-grey-base);
        }

        &.dashed {
            border-style: dashed;
        }
    }

    &.xx-small {
        width: 24px;
        height: 24px;
    }
}

.v-navigation-drawer {
    // min-height: -webkit-fill-available !important;
    // height: -webkit-fill-available !important;
    // -webkit-box-orient: horizontal;
    // -webkit-box-direction: normal;
    // -ms-flex-direction: row;
    // flex-direction: row;
    // // overflow: hidden !important;
    // // flex-shrink: 0;
    // position: relative;

    &.v-navigation-drawer--mini-variant {
        width: 80px !important;
    }

    .v-navigation-drawer__content {
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // overflow: visible;
    }

    .v-navigation-drawer__border {
        display: none;
    }
}

.menu-expand {
    position: absolute;
    right: -11px;
    top: 32px;
    width: 22px !important;
    height: 22px !important;
    z-index: 99999999;
    // transition: all 0.2s;
}

.search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;

    .v-input__control {
        .v-input__slot {
            background: var(--v-grey-base);
        }
    }

    @media (max-width: 1264px) {
        position: static;
        top: initial;
        left: initial;
        transform: initial;
        width: auto;
        max-width: 350px;
    }
}

.content-list {
    .v-image {
        border-radius: 4px !important;
        background-color: #eeeeee;
    }

    .v-card {
        overflow: hidden;
        border: 1px solid var(--v-grey-base) !important;

        &.active {
            position: relative;
            border: 1px solid var(--v-success-base) !important;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--v-success-base);
                opacity: 0.15;
            }
        }
    }
}

.v-avatar {
    border-radius: 4px !important;
    overflow: hidden;

    &.icon {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--v-primary-base);
            opacity: 0.12;
        }
    }

    &.active {
        border: 3px solid var(--v-primary-base);
    }
}

.navigation-inactive {
    .v-icon {
        color: var(--v-dark-base) !important;
    }

    &.v-list-item--disabled {
        opacity: 0.3;

        .v-list-item__content {
            z-index: 1;

            .v-list-item__title {
                color: var(--v-dark-base) !important;
            }
        }
    }
}

.navigation-active {
    &:before {
        opacity: 1 !important;
        background-color: var(--v-primary-base) !important;
    }

    .v-list-item__content {
        z-index: 1;

        .v-list-item__title {
            color: #ffffff !important;
        }
    }

    .v-icon {
        color: #ffffff !important;
    }
}

.header-tabs {
    background: var(--v-grey-base) !important;

    .tabs-group {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        min-width: 0;

        .tabs {
            display: inline-flex;
            align-items: flex-end;
            box-sizing: border-box;
            line-height: 1;
            position: relative;
            z-index: 1;
            user-select: none;
            width: 100%;
            height: 100%;

            .tab {
                background-color: transparent;
                border-radius: 4px 4px 0 0;
                transition: background-color 0.2s;
                white-space: nowrap;
                flex: 1;
                overflow: hidden;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%; // Change this for height
                cursor: pointer;
                margin-left: -1px;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 20px;
                    background: rgba(#222222, 0.3);
                }

                &:hover {
                    background-color: var(--v-background-base);

                    &:after {
                        background: transparent;
                    }
                }

                p {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.active {
                    background: var(--v-light-base);

                    &:after {
                        background: transparent;
                    }

                    .v-icon,
                    p {
                        color: var(--v-primary-base);
                    }
                }

                &.dragging {
                    opacity: 1;
                }
            }
        }
    }
}

.muted {
    opacity: 0.7;
}

.muted-1 {
    opacity: 0.5;
}

.muted-2 {
    opacity: 0.3;
}

.spacer {
    width: 100%;
    height: 100px;
}

.v-bottom-navigation {
    box-sizing: initial !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.full-height {
    height: 100%;
}

.padded-height {
    height: 100% !important;
}

.fill-height {
    height: -webkit-fill-available !important;
}

.fill-available {
    min-height: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
}

.max-height-100 {
    max-height: 100%;
}

.min-height-100 {
    min-height: 100%;
}

.full-width {
    width: 100%;
}

.max-width-300 {
    max-width: 300px;
}

.max-width-500 {
    max-width: 500px;
}

.max-width-700 {
    max-width: 700px;
}

.max-width-900 {
    max-width: 900px;
}

.p-absolute {
    position: absolute !important;

    &.fit {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.center-y {
        top: 50%;
        transform: translateY(-50%);
    }

    &.center-x {
        left: 50%;
        transform: translateX(-50%);
    }

    &.top {
        top: 0px;
    }

    &.top5 {
        top: 5px;
    }

    &.top10 {
        top: 10px;
    }

    &.top15 {
        top: 15px;
    }

    &.top20 {
        top: 20px;
    }

    &.bottom {
        bottom: 0px;
    }

    &.bottom-5 {
        bottom: -5px;
    }

    &.bottom-10 {
        bottom: -10px;
    }

    &.bottom5 {
        bottom: 5px;
    }

    &.bottom10 {
        bottom: 10px;
    }

    &.bottom15 {
        bottom: 15px;
    }

    &.bottom20 {
        bottom: 20px;
    }

    &.left {
        left: 0;
    }

    &.left5 {
        left: 5px;
    }

    &.left10 {
        left: 10px;
    }

    &.left15 {
        left: 15px;
    }

    &.left20 {
        left: 20px;
    }

    &.left-5 {
        left: -5px;
    }

    &.left-10 {
        left: -10px;
    }

    &.left-15 {
        left: -15px;
    }

    &.left-20 {
        left: -20px;
    }

    &.left-30 {
        left: -30px;
    }

    &.left-40 {
        left: -40px;
    }

    &.left-50 {
        left: -50px;
    }

    &.left24 {
        left: 24px;
    }

    &.right {
        right: 0;
    }

    &.right5 {
        right: 5px;
    }

    &.right10 {
        right: 10px;
    }

    &.right15 {
        right: 15px;
    }

    &.right20 {
        right: 20px;
    }
}

.p-relative {
    position: relative !important;
}

.p-fixed {
    position: fixed !important;

    &.center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.top5 {
        top: 5px;
    }

    &.top10 {
        top: 10px;
    }

    &.top15 {
        top: 15px;
    }

    &.top20 {
        top: 20px;
    }

    &.bottom5 {
        bottom: 5px;
    }

    &.bottom10 {
        bottom: 10px;
    }

    &.bottom15 {
        bottom: 15px;
    }

    &.bottom20 {
        bottom: 20px;
    }

    &.left5 {
        left: 5px;
    }

    &.left10 {
        left: 10px;
    }

    &.left15 {
        left: 15px;
    }

    &.left20 {
        left: 20px;
    }

    &.right5 {
        right: 5px;
    }

    &.right10 {
        right: 10px;
    }

    &.right15 {
        right: 15px;
    }

    &.right20 {
        right: 20px;
    }
}

.no-selection {
    -webkit-user-select: none;
    user-select: none;
}

.zindex-1 {
    z-index: 1;
}

.zindex-2 {
    z-index: 2;
}

.zindex-3 {
    z-index: 3;
}

.zindex-4 {
    z-index: 4;
}

.zindex-5 {
    z-index: 5;
}

.zindex-6 {
    z-index: 6;
}

.zindex-7 {
    z-index: 7;
}

.zindex-8 {
    z-index: 8;
}

.zindex-9 {
    z-index: 9;
}

.zindex-10 {
    z-index: 10;
}

.zindex-11 {
    z-index: 11 !important;
}

.zindex-30 {
    z-index: 30 !important;
}

.zindex-max {
    z-index: 9999 !important;
}

.opacity-30 {
    opacity: 30% !important;
}

.opacity-40 {
    opacity: 40% !important;
}

.opacity-50 {
    opacity: 50% !important;
}

.opacity-60 {
    opacity: 60% !important;
}

.opacity-70 {
    opacity: 70% !important;
}

.opacity-80 {
    opacity: 70% !important;
}

.opacity-90 {
    opacity: 70% !important;
}

.height-10 {
    height: 10px !important;
}

.height-20 {
    height: 20px !important;
}

.height-30 {
    height: 30px !important;
}

.height-40 {
    height: 40px !important;
}

.height-50 {
    height: 50px !important;
}

.height-60 {
    height: 60px !important;
}

.height-70 {
    height: 70px !important;
}

.height-80 {
    height: 80px !important;
}

.height-90 {
    height: 90px !important;
}

.height-100 {
    height: 100px !important;
}

.status-cell {
    span {
        white-space: nowrap;
        font-size: 10px !important;

        &.inline-tag {
            position: relative;
            color: var(--v-error-base);
            padding: 4px 7px;
            // text-transform: uppercase;
            border-radius: 4px;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--v-error-base);
                opacity: 0.12;
                border-radius: 4px;
            }

            &.solid {
                &:after {
                    opacity: 1;
                }
            }

            &.active {
                color: var(--v-dark-base);

                &:after {
                    background: var(--v-success-base);
                }
            }

            &.draft {
                color: var(--v-dark-base);

                &:after {
                    background: var(--v-warning-base);
                }
            }

            &.deleted {
                color: var(--v-dark-base);

                &:after {
                    background: var(--v-error-base);
                }
            }

            &.expired,
            &.rejected {
                color: var(--v-error-base);

                &:after {
                    background: var(--v-error-base);
                }
            }

            &.pending {
                color: var(--v-dark-base);

                &:after {
                    background: var(--v-info-base);
                }
            }

            &.archived {
                color: var(--v-dark-base);

                &:after {
                    background: var(--v-dark-base);
                }
            }

            &.incomplete {
                color: #ffffff;

                &:after {
                    background: var(--v-dark-base);
                }
            }
        }
    }
}

.sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;

    &.top {
        top: 0;
    }

    &.bottom {
        bottom: 0;
    }

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}

.safe-area-padding-top {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
    background: rgba(#5f54e0, 0.3);
}

.safe-area-padding-bottom {
    padding-bottom: constant(safe-area-inset-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
}

.max-height-100 {
    max-height: 100%;
}

th.valign-center {
    span {
        vertical-align: middle;
    }
}

div.v-text-field--outlined fieldset {
    // border-color: var(--v-primary-light-base);
    // background: #FFFFFF !important;
}

hr {
    margin: 15px 0;
    border: none;
    border-bottom: 1px solid var(--v-grey-base);
}

.v-input {
    .v-input__control {
        .v-input__slot {
            .v-input__prepend-inner {
                z-index: 1;
            }
        }
    }
}

.v-btn:hover:before,
.v-tab:hover:before {
    background-color: transparent;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
