import Vue from "vue";
import moment from "moment";

export default () => {
    Vue.prototype.$status = Vue.observable({
        // competitionHasNotStarted(competition) {
        //     let submission = competition?.data?.submission;

        //     let startDate = moment(submission.startDate).format("YYYY-MM-DD");
        //     let currentDate = moment().format("YYYY-MM-DD");

        //     let hasNotStarted = startDate > currentDate;

        //     return hasNotStarted;
        // },
        competitionOpen(competition) {
            let submission = competition?.data?.submission;
            let judging = competition?.data?.judging;

            let submissionClosed = submission?.closed;

            let submissionStartDate = moment(submission?.startDate).format("YYYY-MM-DD");
            let submissionEndDate = moment(submission?.endDate).format("YYYY-MM-DD");
            let judgingStartDate = moment(judging?.startDate).format("YYYY-MM-DD");
            let currentDate = moment().format("YYYY-MM-DD");

            let inDateRange = currentDate >= submissionStartDate && currentDate < submissionEndDate;
            let inJudging = currentDate >= judgingStartDate;

            return !submissionClosed && !inJudging && inDateRange;
        },
        competitionJudging(competition) {
            let submission = competition?.data?.submission;
            let judging = competition?.data?.judging;

            let submissionClosed = submission?.closed;
            let judgingClosed = judging?.closed;

            let submissionEndDate = moment(submission?.endDate).format("YYYY-MM-DD");
            let judgingStartDate = moment(judging?.startDate).format("YYYY-MM-DD");
            let judgingEndDate = moment(judging?.endDate).format("YYYY-MM-DD");
            let currentDate = moment().format("YYYY-MM-DD");

            let outOfDateRange = currentDate >= submissionEndDate;
            let inDateRange = currentDate >= judgingStartDate && currentDate < judgingEndDate;

            // return (inDateRange || outOfDateRange || (submissionClosed && inDateRange)) && !judgingClosed;
            return inDateRange && !judgingClosed;
        },
        competitionSubmissionClosed(competition) {
            let submission = competition?.data?.submission;
            let judging = competition?.data?.judging;

            let submissionClosed = submission.closed;
            let judgingClosed = judging?.closed;

            let submissionEndDate = moment(submission?.endDate).format("YYYY-MM-DD");
            let judgingStartDate = moment(judging?.startDate).format("YYYY-MM-DD");
            let currentDate = moment().format("YYYY-MM-DD");

            let inJudging = currentDate >= judgingStartDate;
            let outOfDateRange = currentDate >= submissionEndDate;

            return (outOfDateRange || !inJudging) && submissionClosed && !judgingClosed;
        },
        competitionClosed(competition) {
            let submission = competition?.data?.submission;
            let judging = competition?.data?.judging;

            // let submissionClosed = submission.closed;
            let judgingClosed = judging?.closed;

            let submissionEndDate = moment(submission?.endDate).format("YYYY-MM-DD");
            let judgingEndDate = moment(judging?.endDate).format("YYYY-MM-DD");
            let currentDate = moment().format("YYYY-MM-DD");

            let afterSubmissionEnd = currentDate > submissionEndDate;
            let afterJudgingEnd = currentDate > judgingEndDate;

            return (afterSubmissionEnd && afterJudgingEnd) || judgingClosed;
        },
        judgingStartDate(competition) {
            let startDate = competition?.data?.judging?.startDate;

            return moment(startDate).format("Do MMMM, YYYY");
        },
        judgingEndDate(competition) {
            let endDate = competition?.data?.judging?.endDate;

            return moment(endDate).format("Do MMMM, YYYY");
        },
        submissionEndDate(competition) {
            let endDate = competition?.data?.submission?.endDate;

            return moment(endDate).format("Do MMMM, YYYY");
        },
        hasEnoughInfo(entry) {
            if (!entry?.title?.length) return false;

            if (!entry?.data?.image || !entry?.data?.image?._id) return false;

            if (!entry?.data?.category || !entry?.data?.category?._id) return false;

            // What's really really important?
            if (
                (!entry.data.cameraUsed && entry.data.competition.data.cameraUsed) ||
                (!entry?.data?.cameraUsed?.length && entry.data.competition.data.cameraUsed)
            )
                return false;
            if (entry?.data?.cameraUsed == "Other") {
                if (!entry?.data?.cameraOther || !entry?.data?.cameraOther?.length) return false;
            }
            let competition = entry?.data?.competition;
            let isPrint = competition?.data?.type == "print";

            if (isPrint) {
                if (!entry?.data?.printerUsed || !entry?.data?.printerUsed?.length) return false;
                if (entry?.data?.printerUsed == "Other") {
                    if (!entry?.data?.printerOther || !entry?.data?.printerOther?.length) return false;
                }

                if (!entry?.data?.paperUsed || !entry?.data?.paperUsed?.length) return false;
                if (entry?.data?.paperUsed == "Other") {
                    if (!entry?.data?.paperOther || !entry?.data?.paperOther?.length) return false;
                }
            }

            return true;
        },
    });
};
