import FluroContent from "./fluro-content";

class Persona extends FluroContent {
    constructor() {
        super("persona");
    }
}

const _Persona = new Persona();
export default _Persona;
