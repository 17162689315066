import Vue from "vue";
import Router from "vue-router";
import Vuetify from "vuetify/lib";
import store from "../store";
import PersonaFluro from "@/modules/app-module/persona";
import app from "../modules/app";
///////////////////////////////////

//Routes

// Artist
const MySubmissions = () => import("./routes/my-submissions");
const MySubmission = () => import("./routes/my-submissions/my-submission");

// Judge
const OnlineJudging = () => import("./routes/online-judging");
const OnlineJudgingItem = () => import("./routes/online-judging/online-judging-item");

// Live
const LiveCompetitions = () => import("./routes/live-competition");
const LiveCompetition = () => import("./routes/live-competition/competition");
// const LiveCategory = () => import("./routes/live-competition/category");
const LiveRoom = () => import("./routes/live-competition/room");
const LiveScribe = () => import("./routes/live-competition/scribe");
const LiveJudge = () => import("./routes/live-competition/judge");
const LiveDisplay = () => import("./routes/live-competition/display");

//Print
const Print = () => import("./routes/printer");
const PrintComp = () => import("./routes/printer/competition");

// Entries
const Entries = () => import("./routes/entries");
const Entry = () => import("./routes/entries/entry");

//Admin
const Admin = () => import("./routes/admin");
const Personas = () => import("./routes/admin/users");
const Persona = () => import("./routes/admin/users/persona");
const Competitions = () => import("./routes/admin/competitions");
const Competition = () => import("./routes/admin/competitions/competition");
const CompetitionCreate = () => import("./routes/admin/competitions/create-competition");
const CompetitionReports = () => import("./routes/admin/competitions/reports");
const CompetitionRooms = () => import("./routes/admin/competitions/rooms");
const CompetitionRoom = () => import("./routes/admin/competitions/room");

// Profile
const Profile = () => import("./routes/profile");
const Settings = () => import("./routes/profile/settings");
const Security = () => import("./routes/profile/security");

// Other
const Faqs = () => import("./routes/faqs");

//User Authentication Routes
const User = () => import("./routes/user/user.vue");
const Login = () => import("./routes/user/login.vue");
const Signup = () => import("./routes/user/signup.vue");
const Forgot = () => import("./routes/user/forgot.vue");
const Reset = () => import("./routes/user/reset.vue");
const Welcome = () => import("./routes/user/welcome");

///////////////////////////////////

//Use the router
Vue.use(Router);

///////////////////////////////////

var array = [];

///////////////////////////////////

// Artist

///////////////////////////////////

array.push({
    name: "my-submissions",
    path: "/my-submissions",
    meta: {
        title: "My Submissions",
        requireUser: true,
        requireArtist: true,
    },
    component: MySubmissions,
});

array.push({
    name: "my-submission",
    path: "/my-submissions/:id",
    meta: {
        title: "My Submission",
        requireUser: true,
        requireArtist: true,
    },
    component: MySubmission,
});

//////////////////////////////////////

// Admin

//////////////////////////////////////

array.push({
    name: "admin",
    path: "/admin",
    meta: {
        title: "Admin",
        requireUser: true,
        requireAdmin: true,
    },
    component: Admin,
    children: [
        {
            name: "admin.users",
            path: "users",
            meta: {
                title: "Users",
                requireUser: true,
                requireAdmin: true,
            },
            component: Personas,
        },
        {
            name: "admin.user",
            path: "users/:id",
            meta: {
                title: "User",
                requireUser: true,
                requireAdmin: true,
            },
            component: Persona,
        },
        {
            name: "admin.competitions",
            path: "competitions",
            meta: {
                title: "Competitions",
                requireUser: true,
                requireAdmin: true,
            },
            component: Competitions,
        },
        {
            name: "admin.competition.create",
            path: "competitions/create",
            meta: {
                title: "Create Competition",
                requireUser: true,
                requireAdmin: true,
            },
            component: CompetitionCreate,
        },
        {
            name: "admin.competition",
            path: "competitions/:id",
            meta: {
                title: "Competition",
                requireUser: true,
                requireAdmin: true,
            },
            component: Competition,
        },
        {
            name: "admin.competition-reports",
            path: "competitions/:id/reports",
            meta: {
                title: "Competition Reports",
                requireUser: true,
                requireAdmin: true,
            },
            component: CompetitionReports,
        },
        {
            name: "admin.competition-rooms",
            path: "competitions/:id/rooms",
            meta: {
                title: "Competition Rooms",
                requireUser: true,
                requireAdmin: true,
            },
            component: CompetitionRooms,
        },
        {
            name: "admin.competition-room",
            path: "competitions/:id/rooms/:room",
            meta: {
                title: "Competition Room",
                requireUser: true,
                requireAdmin: true,
            },
            component: CompetitionRoom,
        },
    ],
});

// array.push({
//     name: "user",
//     path: "/admin/user/:id",
//     meta: {
//         title: "User",
//         requireUser: true,
//         requireAdmin: true,
//     },
//     component: Persona,
// });

//////////////////////////////////////

// Judge

//////////////////////////////////////

array.push({
    name: "online-judging",
    path: "/online-judging",
    meta: {
        title: "Online Judging",
        requireUser: true,
        requireJudge: true,
    },
    component: OnlineJudging,
});

array.push({
    name: "online-judging-submission",
    path: "/online-judging/:id",
    meta: {
        title: "Online Judging",
        requireUser: true,
        requireJudge: true,
    },
    component: OnlineJudgingItem,
});

//////////////////////////////////////

// Live

//////////////////////////////////////

array.push({
    name: "live-competitions",
    path: "/live-competitions",
    meta: {
        title: "Live Competitions",
        requireUser: true,
        requireJudge: true,
    },
    component: LiveCompetitions,
});

array.push({
    name: "live-competitions-id",
    path: "/live-competitions/:competition",
    meta: {
        title: "Live Competitions",
        requireUser: true,
        requireJudge: true,
    },
    component: LiveCompetition,
});

// array.push({
//     name: "live-competitions-category",
//     path: "/live-competitions/:competition/:category",
//     meta: {
//         title: "Live Competitions",
//         requireUser: true,
//         requireJudge: true,
//     },
//     component: LiveCategory,
// });

array.push({
    name: "live-competitions-room",
    // path: "/live-competitions/:competition/:category/:room",
    path: "/live-competitions/:competition/:room",
    meta: {
        title: "Live Competitions",
        requireUser: true,
        requireJudge: true,
        hideFooter: true,
        hideNavigation: true,
    },
    component: LiveRoom,
});

array.push({
    name: "live-competitions-scribe",
    // path: "/live-competitions/:competition/:category/:room/:scribe",
    path: "/live-competitions/:competition/:room/:scribe/scribe",
    meta: {
        title: "Live Competitions",
        requireUser: true,
        requireJudge: true,
        hideFooter: true,
        hideNavigation: true,
    },
    component: LiveScribe,
});

array.push({
    name: "live-competitions-judge",
    // path: "/live-competitions/:competition/:category/:room/:judge",
    path: "/live-competitions/:competition/:room/:judge/judge",
    meta: {
        title: "Live Competitions",
        requireUser: true,
        requireJudge: true,
        hideFooter: true,
        hideNavigation: true,
    },
    component: LiveJudge,
});

array.push({
    name: "live-competitions-display",
    // path: "/live-competitions/:competition/:category/:room/:scribe/display",
    path: "/live-competitions/:competition/:room/:scribe/display",
    meta: {
        title: "Live Competitions",
        requireUser: true,
        requireJudge: true,
        hideFooter: true,
        hideNavigation: true,
    },
    component: LiveDisplay,
});

//////////////////////////////////////

// Entries

//////////////////////////////////////

array.push({
    name: "entries",
    path: "/entries",
    meta: {
        title: "Entries",
        requireUser: true,
        requireJudge: true,
    },
    component: Entries,
});

array.push({
    name: "entry",
    path: "/entries/:id",
    meta: {
        title: "Entry",
        requireUser: true,
        requireJudge: true,
        hideFooter: true,
        hideNavigation: true,
    },
    component: Entry,
});

//////////////////////////////////////

// Auth

//////////////////////////////////////

array.push({
    name: "profile",
    path: "/profile",
    meta: {
        title: "Start",
        requireUser: true,
    },
    component: Profile,
    children: [
        {
            name: "profile.settings",
            path: "settings",
            meta: {
                title: "Settings",
                requireUser: true,
            },
            component: Settings,
        },
        {
            name: "profile.security",
            path: "security",
            meta: {
                title: "Security",
                requireUser: true,
            },
            component: Security,
        },
    ],
});

//////////////////////////////////////

// Other

//////////////////////////////////////

array.push({
    name: "faqs",
    path: "/faqs",
    meta: {
        title: "FAQs",
        requireUser: true,
    },
    component: Faqs,
});

//////////////////////////////////////

// Printer

//////////////////////////////////////
array.push({
    name: "print",
    path: "/print",
    meta: {
        title: "Print",
        requireUser: true,
        requirePrinter: true,
    },
    component: Print,
});
array.push({
    name: "print.competition",
    path: "/print/:id",
    meta: {
        title: "Print Competition",
        requireUser: true,
        requirePrinter: true,
    },
    component: PrintComp,
});

//////////////////////////////////////

// Auth

//////////////////////////////////////

array.push({
    name: "auth",
    path: "/auth",
    meta: {
        title: "Start",
        denyUser: true,
    },
    component: User,
    children: [
        {
            name: "auth.welcome",
            path: "welcome",
            meta: {
                title: "Welcome",
                requireUser: true,
                hideFooter: true,
                hideNavigation: true,
            },
            component: Welcome,
        },
        {
            name: "auth.login",
            path: "login",
            meta: {
                title: "Login",
                denyUser: true,
                hideFooter: true,
                hideNavigation: true,
            },
            component: Login,
        },
        {
            name: "auth.signup",
            path: "signup",
            meta: {
                title: "Sign Up",
                denyUser: true,
                hideFooter: true,
                hideNavigation: true,
            },
            component: Signup,
        },
        {
            name: "auth.forgot",
            path: "forgot",
            meta: {
                title: "Forgot Password",
                denyUser: true,
                hideFooter: true,
                hideNavigation: true,
            },
            component: Forgot,
        },
        {
            name: "auth.reset",
            path: "reset",
            meta: {
                title: "Reset Your Password",
                denyUser: true,
                hideFooter: true,
                hideNavigation: true,
            },
            component: Reset,
            props: (route) => ({
                token: route.query.token,
            }),
        },
    ],
});

///////////////////////////////////

var router = new Router({
    mode: "history",
    routes: array,
});

///////////////////////////////////

router.beforeEach(async (to, from, next) => {
    let user = store.getters["fluro/user"];
    setTimeout(async () => {
        let org = store.getters["org/getOrg"];
        //Getting org from URL
        const host = window.location.host;

        let queryOrg = {
            _type: "article",
            definition: "organisation",
            "data.links.awards": host,
        };

        if (!store.getters["org/getOrgData"]?.data?.realmGroup?.realmLive?._id) {
            let orgData = null;
            if (!org) {
                console.log("URL ROUTE");
                orgData = await Vue.prototype.$fluro.api.post("/content/_query", queryOrg).then(({ data }) => data[0]);
            } else {
                console.log("ID ROUTE");
                orgData = await Vue.prototype.$fluro.api.get(`/content/organisation/${org}`).then(({ data }) => data);
            }

            //Get theme Data:
            const light = await Vue.prototype.$fluro.api
                .get(`/content/theme/${orgData.data.themes.light._id}`)
                .then(({ data }) => data);

            const dark = await Vue.prototype.$fluro.api
                .get(`/content/theme/${orgData.data.themes.dark._id}`)
                .then(({ data }) => data);

            //Set data
            if (light) {
                sessionStorage.setItem("lightTheme", JSON.stringify(light));
                Vue.prototype.$app.lightTheme = light.data;
            }
            if (dark) {
                sessionStorage.setItem("darkTheme", JSON.stringify(dark));
                Vue.prototype.$app.darkTheme = dark.data;
            }

            store.commit("org/setOrg", orgData._id);
            sessionStorage.setItem("org", orgData._id);
            store.commit("org/setOrgData", orgData);
            sessionStorage.setItem("orgData", JSON.stringify(orgData));
            Vue.prototype.$app.currency = orgData.data.currency;
            Vue.prototype.$app.liveIntegration = orgData.data.integration._id;
            Vue.prototype.$app.publicRealm = orgData.data.realmGroup.realmPublic._id;
            Vue.prototype.$app.liveRealm = orgData.data.realmGroup.realmLive._id;
            Vue.prototype.$app.sandboxRealm = orgData.data.realmGroup.realmSandbox._id;
        }

        if (!JSON.parse(sessionStorage.getItem("lightTheme"))) {
            console.log("LIGHT THEME NOT FOUND!", JSON.parse(sessionStorage.getItem("lightTheme")));
            const orgData = store.getters["org/getOrgData"];
            console.log("THEME RETRY ORG DATA", orgData);

            const light = await Vue.prototype.$fluro.api
                .get(`/content/theme/${orgData.data.themes.light._id}`)
                .then(({ data }) => data);

            if (light) {
                console.log("SETTING LIGHT THEME AGAIN", light);
                sessionStorage.setItem("lightTheme", JSON.stringify(light));
                Vue.prototype.$app.lightTheme = light.data;
            }
        }

        if (!JSON.parse(sessionStorage.getItem("darkTheme"))) {
            console.log("DARK THEME NOT FOUND!", JSON.parse(sessionStorage.getItem("darkTheme")));
            const orgData = store.getters["org/getOrgData"];
            console.log("THEME RETRY ORG DATA", orgData);

            const dark = await Vue.prototype.$fluro.api
                .get(`/content/theme/${orgData.data.themes.dark._id}`)
                .then(({ data }) => data);

            if (dark) {
                console.log("SETTING DARK THEME AGAIN", dark);
                sessionStorage.setItem("darkTheme", JSON.stringify(dark));
                Vue.prototype.$app.darkTheme = dark.data;
            }
        }

        if (user?.permissionSets) {
            //Check permissions again. Due to this needed the live realm to check now it needs to be called after this has been set.
            //App.js works for the first load after logging in, but sometimes the liveRealm data isnt there yet. Hence the check here
            let persona = await PersonaFluro.get(user.persona).then(({ data }) => data);
            console.log("Before", Vue.prototype.$app.isPrinter, Vue.prototype.$app.isJudge, Vue.prototype.$app.isAdmin);
            Vue.prototype.$app.isJudge = persona.permissionSets.some(
                (permissionSet) =>
                    permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.judgeRole) &&
                    permissionSet.realms.some((realm) => realm._id == Vue.prototype.$app.liveRealm)
            );

            //For the printing service
            Vue.prototype.$app.isPrinter = persona.permissionSets.some(
                (permissionSet) =>
                    permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.printerRole) &&
                    permissionSet.realms.some((realm) => realm._id == Vue.prototype.$app.liveRealm)
            );

            Vue.prototype.$app.isAdmin = persona.permissionSets.some(
                (permissionSet) =>
                    permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.adminRole) &&
                    permissionSet.realms.some((realm) => realm._id == Vue.prototype.$app.liveRealm)
            );
            console.log("After", Vue.prototype.$app.isPrinter, Vue.prototype.$app.isJudge, Vue.prototype.$app.isAdmin);
        }
    }, 1000);

    // Get the org from the store and get the data from fluro if it hasnt already been loaded
    if (!to || !to.meta || !to.name) {
        console.log("NO ROUTE");
        return next("/auth/welcome");
    }
    if (to.meta) {
        //Get the user session from fluro
        // let user = Vue.prototype.$app.user;

        //If the route only allows logged in users
        if (to.meta.requireUser && !user) return next(`/auth/login`);

        if (to.meta.denyUser && user) return next("/my-submissions");

        if (to.name === "profile") return next("/profile/settings");

        if (to.name === "admin") return next("/admin/users");

        if (user) {
            await Vue.prototype.$app.initialised();

            if (to.name === "auth.welcome") {
                if (Vue.prototype.$app.isArtist) return next("/my-submissions");
                if (Vue.prototype.$app.isJudge) return next("/online-judging");
                if (Vue.prototype.$app.isPrinter) return next("/print");
                if (Vue.prototype.$app.isAdmin) return next("/admin");
            }

            if (to.meta.requireArtist && !Vue.prototype.$app.isArtist) return next("/auth/welcome");

            if (to.meta.requireJudge && !Vue.prototype.$app.isJudge) return next("/auth/welcome");

            if (to.meta.requirePrinter && !Vue.prototype.$app.isPrinter) return next("/auth/welcome");

            if (to.meta.requireAdmin && !Vue.prototype.$app.isAdmin) return next("/auth/welcome");
        }
    }

    return next();
});

// router.afterEach((to, from) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         to.meta.title = to.meta.title + "Test";
//     });
// });

///////////////////////////////////

export default router;
