import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";

import "@mdi/font/css/materialdesignicons.css";

///////////////////////////////////////

import FluroVue from "fluro-vue";
Vue.use(FluroVue, { store });

/////////////////////////////////////////////////////

import Meta from "vue-meta";

Vue.use(Meta, {
    keyName: "meta",
    attribute: "data-vue-meta",
    ssrAttribute: "data-vue-meta-server-rendered",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: true,
});

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

/////////////////////////////////////////////////////

//Listen for when the user session changes
var fluro = Vue.prototype.$fluro;
fluro.auth.addEventListener("change", userUpdated);

/////////////////////////////////////////////////////

//Get current org, timeout is needed to make sure the query param is in the URL
setTimeout(() => {
    console.log("LOADING");
    // Load 'org' value from the local storage
    const orgFromSessionStorage = sessionStorage.getItem("org");
    const orgData = JSON.parse(sessionStorage.getItem("orgData"));
    // Get the initial route query parameters
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const orgFromQuery = params.org;
    // Use the 'org' value from the query parameters, local storage

    const org = orgFromQuery || orgFromSessionStorage;

    // Commit the 'setOrg' mutation and save the 'org' value to the local storage
    store.commit("org/setOrg", org);
    sessionStorage.setItem("org", org);
    if (orgData?._id == org) {
        store.commit("org/setOrgData", orgData);
        sessionStorage.setItem("orgData", JSON.stringify(orgData));
        const light = JSON.parse(sessionStorage.getItem("lightTheme"));
        const dark = JSON.parse(sessionStorage.getItem("darkTheme"));

        //set data
        if (light) {
            Vue.prototype.$app.lightTheme = light.data;
        }
        if (dark) {
            Vue.prototype.$app.darkTheme = dark.data;
        }

        Vue.prototype.$app.currency = orgData.data.currency;
        Vue.prototype.$app.liveIntegration = orgData.data.integration._id;
        Vue.prototype.$app.liveRealm = orgData.data.realmGroup.realmLive._id || orgData.data.realmGroup.realmLive;
        Vue.prototype.$app.sandboxRealm = orgData.data.realmGroup.realmSandbox;
        Vue.prototype.$app.publicRealm = orgData.data.realmGroup.realmPublic;
    }
}, 50);

function initialise(user) {
    if (user.persona !== previousLoggedInUser?.persona) {
        store.commit("email", user.email);
        Vue.prototype.$app.init(user);
    }

    previousLoggedInUser = user;
}

function goToLogin() {
    Vue.prototype.$app.clearSession();
    previousLoggedInUser = null;

    if ((router.currentRoute.name || "").indexOf("auth.") < 0) {
        router.push({ name: "auth.login" });
    }
}

/////////////////////////////////////////////////////

//Keep track of whether the user is logged in
var synced = false;
var previousLoggedInUser = null;

fluro.auth.sync().then((user) => {
    if (user) {
        initialise(user);
    } else {
        goToLogin();
    }

    synced = true;
});

/////////////////////////////////////////////////////

// App Module
import AppModule from "@/modules/app.js";
Vue.use(AppModule, { api: fluro.api, store });

import Status from "@/modules/status";
Vue.use(Status);

//Everytime the user changes
function userUpdated(user) {
    console.log("DETECTED USER CHANGE", user);

    if (!synced) {
        return;
    }

    if (user) {
        console.log("did we try to initialise user");
        initialise(user);
        console.log("did we finish the initialise user");
    } else {
        console.log("go to login");
        goToLogin();
    }
}

/////////////////////////////////////////////////////

//Listen for general Fluro errors and print them to screen
//then you can thow an error from anywhere like this this.$fluro.error(err)
fluro.addEventListener("error", function (message) {
    console.log("Error", message);
});

/////////////////////////////////////////////////////

//Use Fluro as an event bus for notifications
//this.$fluro.notication('some message', options) it means we can swap
//out the component we use to render the notifications easily
fluro.addEventListener("notification", function (details) {
    console.log("Notification event", details);
});

///////////////////////////////////////

//Include filters globally
Vue.prototype.$filters = Vue.options.filters;

Vue.directive("blur", {
    inserted: function (el) {
        el.onfocus = (ev) => ev.target.blur();
    },
});

Vue.filter("capitalize", function (value) {
    var string = value;
    return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter("currency", function (value) {
    if (!value) return "$0.00";

    return "$" + value / 100;
});

///////////////////////////////////////

// EVENT BUS
export const EventBus = new Vue();

// import '@/styles/styles.scss'

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
