import _ from "lodash";
import moment from "moment";

import { default as FluroContent, API } from "./app-module/fluro-content";

import Persona from "@/modules/app-module/persona";
export default {
    install: function (Vue, { api, store }) {
        if (!api) throw new Error("API for fluro endpoint required");
        if (!store) throw new Error("Vuex store instance required");
        API.use(api);
        console.log(store.getters["org/getOrg"]);
        Vue.prototype.$app = Vue.observable({
            ready: false,
            user: null,
            loading: false,
            error: {},
            isArtist: false,
            isJudge: false,
            isAdmin: false,
            isPrinter: false,
            // lightTheme: null,
            // darktheme: null,
            currency: "AUD",
            orgRealm: "64407a3b87fc285b5075bcfc",
            oldLiveRealm: "6279dd9b8af46806dbd7b2af",
            peopleRealm: "6449fc2f18777213cf30c653",
            liveRealm: "6279dd9b8af46806dbd7b2af", // Photo Awards - Live
            sandboxRealm: "62d0b451f69e5f15bebb6fec", // Photo Awards - Sandbox
            publicRealm: "627b16eb37396f06dadbd0d1",
            liveIntegration: null, //No default to stop purchasing without an org
            sandboxIntegration: "62d0b881f69e5f15bebb7023",
            judgeRole: "627d928f7818fb5f6bf00da4",
            artistRole: "6279fceec5d6cf2ff03d7f65",
            printerRole: "6473e1fdb7147b22689788a3",
            adminRole: "627d928437203507d1771fd0",
            imageToken: "$2a$10$uf.eGW2Ha8m6ErkvMv4sT.btOemjQpOr2ptLng2SDU/BWZV4PMvWq",
            initialised: async () => {
                while (!Vue.prototype.$app.ready) {
                    await new Promise((resolve) => setTimeout(resolve, 300));
                }

                return;
            },
            init: async (user) => {
                Vue.prototype.$app.loading = true;

                await Vue.prototype.$fluro.resetCache();

                // Set defaults
                Vue.prototype.$app.user = user;

                let persona = await Persona.get(Vue.prototype.$app.user.persona).then(({ data }) => data);

                Vue.prototype.$app.isArtist = persona.permissionSets.some((permissionSet) =>
                    permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.artistRole)
                );
                Vue.prototype.$app.isJudge = persona.permissionSets.some(
                    (permissionSet) =>
                        permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.judgeRole) &&
                        permissionSet.realms.some((realm) => realm._id == Vue.prototype.$app.liveRealm)
                );

                //For the printing service
                Vue.prototype.$app.isPrinter = persona.permissionSets.some(
                    (permissionSet) =>
                        permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.printerRole) &&
                        permissionSet.realms.some((realm) => realm._id == Vue.prototype.$app.liveRealm)
                );

                Vue.prototype.$app.isAdmin = persona.permissionSets.some(
                    (permissionSet) =>
                        permissionSet.roles.some((permission) => permission._id == Vue.prototype.$app.adminRole) &&
                        permissionSet.realms.some((realm) => realm._id == Vue.prototype.$app.liveRealm)
                );

                Vue.prototype.$app.ready = true;

                Vue.prototype.$app.loading = false;
            },
            clearSession() {
                Vue.prototype.$app.user = null;
                Vue.prototype.$app.ready = false;
                Vue.prototype.$app.isArtist = false;
                Vue.prototype.$app.isJudge = false;
                Vue.prototype.$app.isAdmin = false;
                Vue.prototype.$app.isPrinter = false;
            },
            exportCSVItems(data) {
                let self = Vue.prototype.$app;

                let title = data.title;
                let results = data.items;
                let exportData = _.map(data.headers, "text");
                let exportKeys = _.map(data.headers, "value");
                let array = new Array(exportData);

                results.forEach((i) => {
                    var values = exportKeys.map((key) => {
                        var value = i.data[key] || i[key];

                        if (_.isArray(value)) {
                            value = value.map((item) => {
                                let obj = item;

                                if (obj?._id) obj = obj.title;

                                // if (obj?.mimetype) obj = obj._id;

                                if (obj?.mimetype) {
                                    // value = value._id;
                                    let filename = i?.data?.entryCode
                                        ? `${i.data.entryCode}.${obj.extension}`
                                        : obj.title;
                                    obj = `https://api.iyponline.com.au/download/${obj._id}/file/${filename}?access_token=${self.imageToken}`;
                                    // obj = Vue.prototype.$fluro.asset.downloadUrl(obj._id, { filename, extension: "jpeg" });
                                }

                                if (obj?.score) obj = obj.score;

                                return obj;
                            });
                        }

                        if (value?.mimetype) {
                            // value = value._id;
                            let filename = i?.data?.entryCode ? `${i.data.entryCode}.${value.extension}` : value.title;
                            value = `https://api.iyponline.com.au/download/${value._id}/file/${filename}?access_token=${self.imageToken}`;
                            // value = Vue.prototype.$fluro.asset.downloadUrl(value._id, { filename, extension: "jpeg" });
                        }

                        if (value?._id) {
                            value = value.title;
                        }

                        if (value?.score) {
                            value = value.score;
                        }

                        if (value == null) {
                            value = "";
                        }

                        return value;
                    });

                    array.push(values);
                });

                self.exportCSV(title + " - " + moment().format("DD-MM-YYYY") + ".csv", array);
            },
            exportCSV(filename, rows) {
                var processRow = function (row) {
                    var finalVal = "";

                    for (var j = 0; j < row.length; j++) {
                        var innerValue = row[j] === null ? "" : row[j].toString();

                        if (row[j] instanceof Date) {
                            innerValue = row[j].toLocaleString();
                        }

                        var result = innerValue.replace(/"/g, '""');

                        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';

                        if (j > 0) finalVal += ",";

                        finalVal += result;
                    }

                    return finalVal + "\n";
                };

                var csvFile = "";

                for (var i = 0; i < rows.length; i++) {
                    csvFile += processRow(rows[i]);
                }

                var blob = new Blob([csvFile], {
                    type: "text/csv;charset=utf-8;",
                });

                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    var link = document.createElement("a");

                    if (link.download !== undefined) {
                        // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);

                        link.setAttribute("href", url);
                        link.setAttribute("download", filename);
                        link.style = "visibility:hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            },
        });
    },
};
